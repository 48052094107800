<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';

interface Link {
  title: string;
  url: string;
}

const currentYear = computed(() => dayjs().format('YYYY'));

const legalLinks: Link[] = [
  { title: 'Privacy Policy', url: '/privacy' },
  { title: 'CA Privacy Notice', url: '/california-privacy-notice' },
  { title: 'Do Not Sell My Personal Information', url: '/ccpa-opt-out' },
  { title: 'T & C', url: '/terms-and-conditions' },
  {
    title: 'Limit the Use of My Sensitive Personal Information',
    url: '/opt-out-of-use-sharing-of-sensitive-information-form',
  },
];
</script>

<template>
  <div class="flex flex-col gap-4 xl:flex-row xl:items-center xl:justify-between">
    <BaseBodyText class="font-semibold text-white" data-test="footer-copyright">
      &copy; Copyright 1999-{{ currentYear }} Nuts.com
    </BaseBodyText>
    <ul class="flex flex-wrap gap-4">
      <li v-for="link in legalLinks" :key="link.title">
        <a :href="link.url" class="hover:no-underline">
          <SmallBodyText
            class="text-stone-200 hover:underline underline-offset-8 decoration-nuts-amber-400 decoration-2"
          >
            {{ link.title }}
          </SmallBodyText>
        </a>
      </li>
    </ul>
    <img
      alt="Digicert EV SSL secure logo"
      data-test="footer-digicert-badge"
      height="48"
      loading="lazy"
      width="66"
      src="@/assets/layout/footer/digicert.svg"
    />
  </div>
</template>
