<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import Container from '@/components/base/layout/Container.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import LegalLinks from '@/components/layout/footer/LegalLinks.vue';
import NavLinkGroupsSection from '@/components/layout/footer/NavLinkGroupsSection.vue';
import SocialLinks from '@/components/layout/footer/SocialLinks.vue';
import SubscribeForm from '@/components/layout/footer/SubscribeForm.vue';
import WarrantyPromise from '@/components/layout/footer/WarrantyPromise.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';

const templateRoot = ref<HTMLElement>();
useRouterLinks(templateRoot, useRouter());
</script>

<template>
  <div ref="templateRoot">
    <div itemscope itemtype="http://schema.org/Corporation" data-test="ptn-footer">
      <meta itemprop="name" content="Nuts.com" />
      <meta itemprop="brand" content="Nuts.com" />
      <meta itemprop="url" content="https://nuts.com" />
      <footer class="bg-nuts-neutral-950" data-test="footer">
        <Container class="py-8">
          <a href="/" data-test="footer-logo" title="Nuts.com">
            <picture>
              <source
                media="(min-width: 1024px)"
                height="72"
                itemprop="logo"
                loading="lazy"
                width="72"
                srcset="@/assets/layout/nuts-stamp-logo.svg"
              />
              <img
                alt="Nuts.com"
                height="64"
                itemprop="logo"
                loading="lazy"
                width="64"
                src="@/assets/layout/nuts-stamp-logo.svg"
              />
            </picture>
          </a>
          <WarrantyPromise class="visible block mt-6 xl:hidden" />
          <div class="grid grid-cols-2 gap-8 md:grid-cols-5 xl:grid-cols-7 xl:gap-14 xl:mt-6">
            <WarrantyPromise class="hidden col-span-2 xl:visible xl:block" />
            <NavLinkGroupsSection />
            <div class="grid gap-3 col-span-full md:col-span-2">
              <BaseBodyText class="font-semibold text-stone-200">
                Be the first to know about new products, promos, &amp; exclusive offers
              </BaseBodyText>
              <SubscribeForm />
              <SocialLinks class="flex gap-4 mx-auto my-0 lg:ml-0" data-test="social-services" />
            </div>
          </div>
          <hr class="h-px m-0 my-8 opacity-50 bg-neutral-300" />
          <LegalLinks class="grid gap-4 xl:flex" />
        </Container>
      </footer>
    </div>

    <div id="dy-bottom-drawer" />
  </div>
</template>
